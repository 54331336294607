import {Box, Text, Button, Container, Flex} from '@chakra-ui/react';
import {useRouter} from 'next/router';
import React from 'react';
import {PageLayout} from '../components/global/page-layout';
import {useUserQuery} from '../graphql/generated';

const Custom404: React.FC = () => {
  const userQuery = useUserQuery();
  const router = useRouter();

  return (
    <PageLayout firstName={userQuery.data?.user?.firstName}>
      <Flex flexDir="column">
        <Box
          textAlign="center"
          mb="8"
          textStyle={{base: 'h5', md: 'h3'}}
          as="h3"
        >
          Page not found
        </Box>
        <Container
          maxW="container.sm"
          color="MID_GREY"
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexDir="column"
        >
          <Text textStyle={{base: 'h7', md: 'h5'}} my={12} textAlign="center">
            Sorry, this page is not part of our collection.
          </Text>
          <Button onClick={() => router.push('/')} colorScheme="aqua">
            back to homepage
          </Button>
        </Container>
      </Flex>
    </PageLayout>
  );
};

export default Custom404;
